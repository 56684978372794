// /* noto-sans-regular - cyrillic_latin */
// @font-face {
//     font-family: 'Noto Sans';
//     font-style: normal;
//     font-weight: 400;
//     src: local('Noto Sans'), local('NotoSans'),
//         url('./theme/fonts/noto-sans/noto-sans.woff2') format('woff2'),
//         /* Chrome 26+, Opera 23+, Firefox 39+ */
//             url('./theme/fonts/noto-sans/noto-sans.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* noto-sans-700 - cyrillic_latin */
// @font-face {
//     font-family: 'Noto Sans';
//     font-style: normal;
//     font-weight: 700;
//     src: local('Noto Sans Bold'), local('NotoSans-Bold'),
//         url('./theme/fonts/noto-sans/noto-sans-bold.woff2') format('woff2'),
//         /* Chrome 26+, Opera 23+, Firefox 39+ */
//             url('./theme/fonts/noto-sans/noto-sans-bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }

// @font-family: 'Noto Sans', helvetica, arial, verdana, sans-serif, FontAwesome;

/* roboto-300 - latin_cyrillic */

body {
    font-size: 12px !important;
    font-weight: 400 !important;
    font-family: 'Rubik', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif !important;
    font-weight: 400 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@primary-color: #3C9FD9;@drawer-body-padding: 0;@body-background: #f0f2f5;@layout-header-background: #fff;@menu-dark-submenu-bg: #0e1541;