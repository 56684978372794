@import '~antd/dist/antd.less';

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ag-theme-alpine .ag-row {
    color: #000000f2;
    font-size: 12px;
}

.ag-theme-alpine .ag-header-cell-label {
    font-size: 11px;
}

.ag-theme-alpine {
    font-family: 'Rubik';
    font-weight: 400;

    .ag-cell-value {
        .ag-react-container {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.custom-tooltip {
    position: absolute;
    pointer-events: none;
    transition: opacity 1s;
    background: #fff;
    min-width: 200px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
}

.custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
}

.custom-tooltip p:first-of-type {
    font-weight: bold;
}

@primary-color: #3C9FD9;@drawer-body-padding: 0;@body-background: #f0f2f5;@layout-header-background: #fff;@menu-dark-submenu-bg: #0e1541;